import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, TextField, LinearProgress, Dialog, DialogTitle, 
  DialogContent, DialogActions, FormControlLabel, Checkbox } from '@mui/material';
import { ArrowForwardIos, CheckCircleOutline, MailOutline } from '@mui/icons-material';
import bg10 from './images/about/donatepic.jpeg';
import { postCreateOrder, postVerifyPayment, sendInvoice, getCountry } from '../Services/AdminServices';

// Helper function to validate phone number (10 digits for Indian numbers)
const validatePhoneNumber = (phone) => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phone);
};

// Helper function to validate email format
const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
};

const SuccessModal = ({ open, onClose }) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <Box sx={{ 
        p: 4, 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        textAlign: 'center'
      }}>
        <CheckCircleOutline 
          sx={{ 
            fontSize: 64, 
            color: '#4CAF50', 
            mb: 2 
          }} 
        />
        <DialogTitle sx={{ 
          fontSize: '1.5rem', 
          fontWeight: 'bold',
          mb: 1
        }}>
          Thank You for Your Support!
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 3, color: 'text.secondary' }}>
            Your donation has been successfully processed. We greatly appreciate your generosity.
          </Typography>
          <Box sx={{ 
            bgcolor: '#f5f5f5', 
            p: 2, 
            borderRadius: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 3
          }}>
            <MailOutline color="primary" />
            <Typography variant="body2">
              An invoice has been sent to your email address. Please check your inbox.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ width: '100%', justifyContent: 'center' }}>
          <Button 
            onClick={onClose}
            variant="contained"
            sx={{
              bgcolor: '#d40032',
              color: 'white',
              '&:hover': {
                bgcolor: '#b30029',
              },
              px: 4
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const DonationForm = ({ category, amount, setAmount, handleOpenModal }) => (
  <Box sx={{ p: 4, height: '100%', display: 'flex', flexDirection: 'column', color: 'white' }}>
    <Typography variant="h3" sx={{ mb: 2, fontSize: { xs: '2rem', md: '3rem' }, mt: 10 }}>
      Donate to {category}
    </Typography>
    <Typography sx={{ mb: 4 }}>
      Help support {category.toLowerCase()} in need. Your donation will make a difference.
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, mt: 4 }}>
      <Typography variant="body2">RAISED: 40000</Typography>
      <Typography variant="body2">GOAL: 80000</Typography>
    </Box>
    <LinearProgress 
      variant="determinate" 
      value={50} 
      sx={{ 
        mb: 4, 
        height: 10,
        backgroundColor: 'rgba(255,255,255,0.3)',
        '& .MuiLinearProgress-bar': {
          backgroundColor: 'white'
        }
      }} 
    />
    <Grid container spacing={2} sx={{ mb: 4, mt: 5 }}>
      {[250, 500, 1000, 2000].map((amt) => (
        <Grid item xs={3} key={amt}>
          <Button
            variant="outlined"
            fullWidth
            sx={{
              color: 'white',
              borderColor: 'rgba(255,255,255,0.5)',
              '&:hover': { backgroundColor: 'rgba(255,255,255,0.1)' },
            }}
            onClick={() => setAmount(amt)}
          >
            {amt}
          </Button>
        </Grid>
      ))}
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <TextField
          fullWidth
          placeholder="Custom Amount"
          value={amount}
          variant="outlined"
          onChange={(e) => setAmount(e.target.value)} 
          sx={{
            input: { color: 'white', '&::placeholder': { color: 'rgba(255,255,255,0.7)' } },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: 'rgba(255,255,255,0.5)' },
              '&:hover fieldset': { borderColor: 'white' },
            },
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          variant="contained"
          onClick={handleOpenModal}
          sx={{
            backgroundColor: 'white',
            color: '#d40032',
            py: 3,
            height: { xs: '58%', lg: '72%' },
            '&:hover': { backgroundColor: 'rgba(255,255,255,0.8)' },
          }}
        >
          Donate Now
        </Button>
      </Grid>
    </Grid>
  </Box>
);

const DonateUs = () => {
  const [amount, setAmount] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('Life School');
  const [open, setOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [needsGSTInvoice, setNeedsGSTInvoice] = useState(false);
  const [donorInfo, setDonorInfo] = useState({ name: '', email: '', phone: '' });
  const [errorFields, setErrorFields] = useState({ name: false, email: false, phone: false });
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const detectCountry = async () => {
      try {
        const response = await getCountry();
        if (response.data.success) {
          setPaymentMethod(response.data.country === "IN" ? "razorpay" : "churchcenter");
        } else {
          // Default to Church Center if country detection fails
          setPaymentMethod("churchcenter");
        }
      } catch (error) {
        console.error("Error detecting country:", error);
        setPaymentMethod("churchcenter"); // Fallback to Church Center
      } finally {
        setIsLoading(false);
      }
    };
    
    detectCountry();
    
    // Include Church Center Modal script
    const script = document.createElement('script');
    script.src = "https://js.churchcenter.com/modal/v1";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleOpenModal = () => {
    if (paymentMethod === "churchcenter") {
      // Open Church Center donation form in modal
      window.open("https://ray-of-love.churchcenter.com/giving?open-in-church-center-modal=true", "_blank");
      return;
    }

    if (amount === 0 || amount === '') {
      alert('Please select an amount');
      return;
    }
    setOpen(true);
  };

  const handleCloseModal = () => {
    setErrorFields({ name: false, email: false, phone: false });
    setNeedsGSTInvoice(false);
    setOpen(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    setAmount(0); // Reset amount after successful donation
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDonorInfo({ ...donorInfo, [name]: value });
    setErrorFields({ ...errorFields, [name]: false });
  };

  const handleDonation = async () => {
    if (amount < 50) {
      alert("Please choose an amount of 50 or above.");
      return;
    }
  
    if (needsGSTInvoice) {
      const newErrorFields = {
        name: !donorInfo.name,
        email: !donorInfo.email,
        phone: !donorInfo.phone,
      };
  
      if (Object.values(newErrorFields).some((error) => error)) {
        setErrorFields(newErrorFields);
        return;
      }
  
      if (donorInfo.phone && !validatePhoneNumber(donorInfo.phone)) {
        setErrorFields((prev) => ({ ...prev, phone: true }));
        return;
      }
  
      if (donorInfo.email && !validateEmail(donorInfo.email)) {
        setErrorFields((prev) => ({ ...prev, email: true }));
        return;
      }
    }
  
    await handleRazorpayPayment();
  };

  const handleRazorpayPayment = async () => {
    try {
      let data = {
        amount: amount,
        email: donorInfo.email
      };
     
      const orderResponse = await postCreateOrder(data);
      
      const { id: order_id, amount: order_amount, currency } = orderResponse.data.order;

      const options = {
        key: "rzp_live_jqg6Wd7jcENICs",
        amount: order_amount,
        currency,
        name: "RAY OF LOVE",
        description: "Donation",
        order_id: order_id,
        handler: async (response) => {
          const paymentId = response.razorpay_payment_id;
          const orderId = response.razorpay_order_id;
          const signature = response.razorpay_signature;

          try {
            const verifyResponse = await postVerifyPayment({
              payment_id: paymentId,
              order_id: orderId,
              signature: signature
            });

            if (verifyResponse.data.success) {
              // If GST invoice is needed, send invoice
              if (needsGSTInvoice && donorInfo.email) {
                try {
                  await sendInvoice({
                    email: donorInfo.email,
                    amount: amount,
                    name: donorInfo.name,
                    phone: donorInfo.phone,
                    payment_id: paymentId
                  });
                } catch (error) {
                  console.error("Error sending invoice:", error);
                }
              }
              handleCloseModal();
              setSuccessModalOpen(true);
            } else {
              alert("Payment verification failed!");
            }
          } catch (error) {
            console.error("Payment verification error", error);
            alert("Error verifying payment!");
          }
        },
        prefill: needsGSTInvoice ? {
          name: donorInfo.name,
          email: donorInfo.email,
          contact: donorInfo.phone,
        } : {},
        theme: {
          color: "#d40032",
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    } catch (error) {
      console.error("Error during order creation", error);
      alert("Error creating order!");
    }
  };

  // Render loading state while detecting country
  if (isLoading) {
    return <div>Loading payment options...</div>;
  }

  const categories = [
    'Life School',
    'Goat Rearing',
    'Youth Outreach',
    'Stitching Center',
  ];

  return (
    <Box id="donate-us-section" sx={{ flexGrow: 1, mb: 5 }}>
      <Grid container sx={{ minHeight: '600px' }}>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, height: '100%', overflow: 'hidden' }}>
            <Grid item xs={12} md={3} lg={3} sx={{ height: { xs: 'auto', md: '100%' }, p: { xs: 2, md: 4 }, backgroundColor: '#222' }}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center' }}>
                {categories.map((category) => (
                  <Box
                    key={category}
                    sx={{
                      color: selectedCategory === category ? 'white' : '#999',
                      py: 1,
                      cursor: 'pointer',
                      '&:hover': { color: 'white' },
                      position: 'relative',
                    }}
                    onClick={() => setSelectedCategory(category)}
                  >
                    <Typography variant="h6">{category}</Typography>
                    {selectedCategory === category && (
                      <ArrowForwardIos
                        sx={{
                          position: 'absolute',
                          left: -20,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: '#d40032',
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={9} lg={9} sx={{ height: '100%', backgroundColor: '#d40032', position: 'relative' }}>
              <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', p: { xs: 2, md: 4 } }}>
                <DonationForm 
                  category={selectedCategory} 
                  amount={amount} 
                  setAmount={setAmount} 
                  handleOpenModal={handleOpenModal} 
                />
              </Box>
              <Box
                sx={{
                  display: { xs: 'none', md: 'block' },
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  width: '35%',
                  height: '100%',
                  backgroundImage: `url(${bg10})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              />
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Razorpay Donor Info Modal (only for Indian donations) */}
      {paymentMethod === "razorpay" && (
        <Dialog open={open} onClose={handleCloseModal} maxWidth="sm" fullWidth>
          <DialogContent>
            {/* Display Selected Donation Amount */}
            <Box sx={{ mb: 2, textAlign: 'center', color: '#d40032' }}>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                Donation Amount: ₹{amount}
              </Typography>
            </Box>

            {/* GST Invoice Checkbox */}
            <FormControlLabel
              control={
                <Checkbox
                  checked={needsGSTInvoice}
                  onChange={(e) => setNeedsGSTInvoice(e.target.checked)}
                  color="primary"
                />
              }
              label="Need a GST Invoice?"
              sx={{ mb: 2 }}
            />

            {/* Donor Info Fields */}
            {needsGSTInvoice && (
              <>
                <TextField
                  margin="dense"
                  name="name"
                  label="Full Name"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleInputChange}
                  error={errorFields.name}
                  helperText={errorFields.name ? "Name is required" : ""}
                  sx={{ mb: 2 }}
                />
                <TextField
                  margin="dense"
                  name="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  onChange={handleInputChange}
                  error={errorFields.email}
                  helperText={errorFields.email ? "Invalid email address" : ""}
                  sx={{ mb: 2 }}
                />
                <TextField
                  margin="dense"
                  name="phone"
                  label="Phone Number"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleInputChange}
                  error={errorFields.phone}
                  helperText={errorFields.phone ? "Invalid phone number" : ""}
                  sx={{ mb: 2 }}
                />
              </>
            )}
          </DialogContent>

          {/* Actions: Cancel & Pay Now buttons */}
          <DialogActions sx={{ padding: '16px' }}>
            <Button
              onClick={handleCloseModal}
              color="secondary"
              variant="outlined"
              sx={{ width: '100%', padding: '10px', fontWeight: 'bold' }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDonation} 
              disabled={isLoading}
              sx={{
                backgroundColor: '#d40032',
                color: 'white',
                width: '100%',
                padding: '10px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#b30029',
                },
              }}
            >
              {isLoading ? "Processing..." : `Donate ₹${amount}`}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Success Modal */}
      <SuccessModal 
        open={successModalOpen} 
        onClose={handleCloseSuccessModal}
      />
    </Box>
  );
};

export default DonateUs;