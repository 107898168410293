import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, Button, IconButton, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/system';
import Masonry from '@mui/lab/Masonry';
import { postGallery, getAllGallery, deleteGallery } from '../Services/AdminServices'; 

const AdminPageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f0f0f0',
  padding: theme.spacing(4),
}));

const ImagePreview = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '200px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
}));

const SuccessMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  color: 'green',
  fontWeight: 'bold',
}));

const AdminGallery = () => {
  const [imageFile, setImageFile] = useState(null);
  const [imageTitle, setImageTitle] = useState('');
  const [galleryItems, setGalleryItems] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [uploadSuccessMessage, setUploadSuccessMessage] = useState('');
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');

  useEffect(() => {
    fetchGalleryItems();
  }, []);

  const fetchGalleryItems = async () => {
    const response = await getAllGallery();
    if (response.data.success) {
      setGalleryItems(response.data.galleryItems);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleUploadSubmit = async () => {
    try {
      if (imageFile && imageTitle) {
        const formData = new FormData();
        formData.append('title', imageTitle);
        formData.append('image', imageFile);
        console.log('Sending request to:', '/gallery/create');
        const response = await postGallery(formData);
        if (response.data.success) {
          fetchGalleryItems();
          setImageFile(null);
          setImageTitle('');
          setPreviewImage('');
          setUploadSuccessMessage('Photo uploaded successfully!');
          setTimeout(() => setUploadSuccessMessage(''), 1500);
        }
      } else {
        alert('Please provide a title for the image and select an image.');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      alert('An error occurred while uploading the image. Please try again.');
    }
  };
  
  const handleDeleteImage = async (id) => {
    const response = await deleteGallery(id);
    if (response.data.success) {
      fetchGalleryItems();
      setDeleteSuccessMessage('Photo deleted successfully!');
      setTimeout(() => setDeleteSuccessMessage(''), 1500);
    }
  };

  return (
    <AdminPageContainer>
      <Container maxWidth="lg">
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" sx={{ mb: 2, color: '#d40032 ' }}>
            Manage Gallery
          </Typography>

          <Box sx={{ mb: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Upload New Image
            </Typography>

            {/* Success Messages */}
            {uploadSuccessMessage && (
              <SuccessMessage>
                {uploadSuccessMessage}
              </SuccessMessage>
            )}
            {deleteSuccessMessage && (
              <SuccessMessage>
                {deleteSuccessMessage}
              </SuccessMessage>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none', mt: 3 }}
                id="upload-image"
              />
              <label htmlFor="upload-image">
                <Button
                  variant="contained"
                  component="span"
                  color="secondary"
                  sx={{ 
                    backgroundColor: '#302c51', 
                    mr: 2 
                  }}
                >
                  Choose Image
                </Button>
              </label>
              {previewImage && (
                <ImagePreview
                  sx={{
                    backgroundImage: `url(${previewImage})`,
                    width: '200px', // Set fixed width for the preview
                    height: '200px', // Set fixed height for the preview
                    ml: 2
                  }}
                />
              )}
            </Box>

            <TextField
              label="Image Title"
              variant="outlined"
              value={imageTitle}
              onChange={(e) => setImageTitle(e.target.value)}
              sx={{ 
                mb: 2,
                width: { xs: '100%', lg: '50%' },
              }}
            />

            <Button
              variant="contained"
              onClick={handleUploadSubmit}
              color="secondary"
              disabled={!imageFile || !imageTitle}
              sx={{ 
                backgroundColor: (!imageFile || !imageTitle) ? '#b0b0b0' : '#302c51' ,ml: { lg: 2 }
              }}
            >
              Upload
            </Button>
          </Box>

          <Typography variant="h6" sx={{ mb: 2 }}>
            Existing Images
          </Typography>
          <Masonry columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
            {galleryItems.map((item) => (
              <Box key={item._id} sx={{ position: 'relative' }}>
                <ImagePreview sx={{ backgroundImage: `url(${item.imagePath.url})` }} />
                <Typography
                  variant="body2"
                  sx={{
                    position: 'absolute',
                    bottom: 8,
                    left: 8,
                    color: 'white',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    p: 1,
                    borderRadius: '4px',
                  }}
                >
                  {item.title}
                </Typography>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    borderRadius: '4px',
                  }}
                >
                  <IconButton
                    sx={{ color: 'white' }}
                    onClick={() => handleDeleteImage(item._id)}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Masonry>
        </Box>
      </Container>
    </AdminPageContainer>
  );
};

export default AdminGallery;
